import { Component } from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  completed = [];
  error = [];
  data = [{name: 'RevelDigital API', 'url': 'https://api.reveldigital.com/health'},
    {name: 'RevelDigital Player Services', 'url': 'https://svc1.reveldigital.com/health'},
    {name: 'RevelDigital Cloud Storage', 'url': 'https://cdn.reveldigital.com/status.json'},
    {name: 'RevelDigital Gadget Server', 'url': 'https://shindig.reveldigital.com/status.json'}];

  constructor(private http: HttpClient) {
    for(let val of this.data){
      this.http.get(val.url).subscribe((res:any)=>{
        if(res.hasOwnProperty('status')){
          if(res.status === 'healthy' || res.status === 'ok'){
            this.completed.push(val.name);
          }else {
            this.error.push(val.name);
          }
        }else{
          this.completed.push(val.name);
        }
      },()=>{
        this.error.push(val.name);
      });
    }
    // try {
    //   this.data.push({name: 'RevelDigital Hub Core', 'url': ''});
    //   const socket = new WebSocket("wss://revelhub.service.signalr.net/client/?hub=commandhub&asrs.op=%2Fcommandhub&registration_key=abb1a4c8-5312-4e66-bc06-8116a1b95bcb&asrs_request_id=5Mt4pm8SAAA%3D&id=AW2IJV5qQw1AtalgVfmzRQ695013161.");
    //   socket.onerror = (error)=> {
    //     this.completed.push('RevelDigital Hub Core');
    //   };
    //
    //
    // } catch (e) {
    //   console.log(e)
    // }

  }

  isSuccess(item) {
    return this.completed.indexOf(item.name) > -1;
  }

  isError(item) {
    return this.error.indexOf(item.name) > -1;
  }
}
